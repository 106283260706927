import { auth, firestore } from '@/services/FirebaseService';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { createStore } from 'vuex'

const store = createStore({
  state: {
    user: undefined,
    settings: {},
    isAdmin: false,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setSettings(state, settings) {
      state.settings = settings;
    },
    setIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
  },
});

export default store;

onAuthStateChanged(auth, async (user) => {
  if (user) {
    const { claims } = await user.getIdTokenResult();
    store.commit('setIsAdmin', claims.isAdmin || false);
    const userDoc = await getDoc(doc(firestore, `${claims.isAdmin ? 'admins' : 'companies'}/${user.uid}`));
    if (userDoc.exists()) {
      store.commit('setUser', userDoc.data());
      return;
    }
  }
  store.commit('setUser', null);
});

const getSettings = async () => {
  const settingsDoc = await getDoc(doc(firestore, 'settings/general'));
  store.commit('setSettings', settingsDoc.data());
};

getSettings();
