<template>
  <div id="butter">
    <header>
    <div class="wrapper">
      <router-link to="/">
        <img :src="`/images/meen-logo${['/news', '/events'].includes($route.path) ? '-w' : ''}.svg`" alt="Midlands Engineering Network" class="header-logo">
      </router-link>
      <nav>
        <div class="nav-item">
          <router-link to="/">Home</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/members">Members</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/events">Events</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/news">News</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/jobs">Jobs</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/resources">Resources</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/about">About</router-link>
        </div>
      </nav>
      <div class="header-actions">
        <div class="account-button" v-if="store.state.user?.company_name">
          <router-link to="/account/profile" class="link">
            <i class="ic ic-user"></i>
            My Account
          </router-link>
          <div class="account-menu">
            <router-link to="/account/profile" class="link">
              <i class="ic ic-profile"></i>
              Company Profile
            </router-link>
            <router-link to="/account/jobs" class="link">
              <i class="ic ic-work"></i>
              Job Openings
            </router-link>
            <a class="link" @click="logout">
              <i class="ic ic-logout"></i>
              Log out
            </a>
          </div>
        </div>
        <router-link to="/login" class="link" v-else>
          <i class="ic ic-login"></i>
          Log in
        </router-link>
        <router-link class="btn" to="/contact">Contact</router-link>
      </div>
      <button class="mobile-nav-button" @click.stop="navOpen = !navOpen">
        <i class="ic ic-menu l"></i>
      </button>
      <div class="mobile-nav" :open="navOpen || null">
        <div class="nav-item">
          <router-link to="/">Home</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/members">Members</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/events">Events</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/news">News</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/jobs">Jobs</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/resources">Resources</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/about">About</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/contact">Contact</router-link>
        </div>
        <div class="nav-item" v-if="store.state.user?.company_name">
          <router-link to="/account/profile">Company Profile</router-link>
        </div>
        <div class="nav-item" v-if="store.state.user?.company_name">
          <router-link to="/account/jobs">Job Openings</router-link>
        </div>
        <div class="nav-item" v-if="store.state.user?.company_name">
          <a @click="logout">
            Log out
          </a>
        </div>
        <div class="nav-item" v-else>
          <router-link to="/login">Log in</router-link>
        </div>
      </div>
    </div>
  </header>
  <main class="page-container">
    <router-view />
  </main>
  <ResourcesSection v-if="!['/resources', '/contact', '/about', '/register'].includes($route.path)" />
  <section class="newsletter">
    <div class="column-left animated-block">
      <div class="content">
        <div class="content-text">
          <h2>Join our newsletter</h2>
          <p>Stay updated on all the latest news and upcoming events. Just enter your email, and we'll keep you in the loop!</p>
        </div>
      </div>
    </div>
    <div class="column-right animated-block">
      <div class="content">
        <form class="newsletter-form" :disabled="loading || null" @submit.prevent="joinNewsletter">
          <label for="newsletter-email">Enter your email below to receive all updates from our network!</label>
          <div class="input-button">
            <input placeholder="email" v-model="newsletterModel.email" :disabled="loading" class="input" type="email" id="newsletter-email" maxlength="255" required>
            <button type="submit" class="btn dark" :disabled="loading">Sign me up</button>
          </div>
          <div class="field checkbox">
            <button class="checkbox" type="button" :checked="newsletterModel.tos || null" @click="newsletterModel.tos = !newsletterModel.tos" :disabled="loading"></button>
            <span>I have read and agree to the <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Data Privacy Policy</a></span>
          </div>
          <div class="success-message" v-if="newsletterSuccess">Thank you for subscribing to our newsletter!</div>
          <div class="success-message error" v-else-if="newsletterError">{{ newsletterError }}</div>
        </form>
      </div>
    </div>
  </section>
  <section class="partners">
    <div class="wrapper">
      <div class="container">
        <div class="logo-container animated-block">
          <img src="@/assets/iesifp-logo.svg" alt="" class="logo">
        </div>
        <div class="logo-container animated-block">
          <img src="@/assets/european-regional-development.svg" alt="" class="logo">
        </div>
        <div class="logo-container animated-block">
          <img src="@/assets/logo-local-authorities.jpg" alt="" class="logo">
        </div>
        <div class="logo-container animated-block">
          <img src="@/assets/logo-ei.png" alt="" class="logo">
        </div>
        <div class="logo-container animated-block">
          <img src="@/assets/leo-logo.svg" alt="" class="logo">
        </div>
      </div>
    </div>
  </section>
  <section class="join-network animated-block">
    <div class="wrapper">
      <h2 class="title">Be part of the Engenuity Network</h2>
      <router-link class="btn dark" to="/register">
        Join the network
        <i class="ic ic-arrow-right"></i>
      </router-link>
    </div>
  </section>
  <footer>
    <div class="wrapper">
      <div class="container">
        <div class="column animated-block">
          <div class="column-header">Menu</div>
          <router-link to="/">Home</router-link>
          <router-link to="/members">Members</router-link>
          <router-link to="/events">Events</router-link>
          <router-link to="/news">News</router-link>
          <router-link to="/jobs">Jobs</router-link>
        </div>
        <div class="column animated-block">
          <div class="column-header">Connect</div>
          <a href="https://www.facebook.com/profile.php?id=100063737761079" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a href="https://www.linkedin.com/in/engenuity-midlands-engineering-network-1a30881aa/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          <a href="https://x.com/Engenuity2" target="_blank" rel="noopener noreferrer">X</a>
        </div>
        <div class="column animated-block">
          <div class="column-header">Info</div>
          <router-link to="/resources">Resources</router-link>
          <router-link to="/about">About</router-link>
          <router-link to="/contact">Contact</router-link>
        </div>
        <div class="column animated-block">
          <div class="column-header">Legal</div>
          <router-link to="/privacy-policy">Privacy Policy</router-link>
          <router-link to="/terms-conditions">Terms & Conditions</router-link>
        </div>
      </div>
      <div class="footer-bottom">
        <router-link to="/">
          <img src="@/assets/meen-logo.svg" alt="Midlands Engineering Network" class="footer-logo animated-block">
        </router-link>
        <div class="footer-copyright animated-block">© Midlands Engineering Network 2024. All rights reserved.</div>
        <div class="footer-sign animated-block">Made with ❤️ by <a href="https://blackpugstudio.com" target="_blank" rel="noopener noreferrer">Black Pug Studio</a></div>
      </div>
    </div>
  </footer>
  </div>
  
</template>

<script lang="ts" setup>
import { nextTick, onMounted, ref, watch } from 'vue';
import { httpsCallable } from 'firebase/functions'
import { auth, functions } from '@/services/FirebaseService';
import ResourcesSection from './components/ResourcesSection.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { signOut } from 'firebase/auth';

const store = useStore();
const router = useRouter();
const route = useRoute();
const newsletterSuccess = ref(false);
const loading = ref(false);
const navOpen = ref(false);
const newsletterError = ref('');
const newsletterModel = ref({
  email: '',
  tos: false,
});

const logout = () => signOut(auth);

const joinNewsletter = async () => {
  loading.value = true;
  newsletterSuccess.value = false;
  newsletterError.value = '';
  try {
    const { email, tos } = newsletterModel.value;
    if (!tos) {
      throw new Error('You must agree to our data privacy policy to subscribe to our newsletter.');
    }
    await httpsCallable(functions, 'joinNewsletter')({ email });
    newsletterSuccess.value = true;
    newsletterModel.value = {
      email: '',
      tos: false,
    };
  } catch (error: any) {
    console.log(error);
    newsletterError.value = error.message;
    loading.value = false;
  }
};

const observer = new IntersectionObserver((entries) => {
  let index = 0;
  entries.forEach((entry) => {
    if (entry.isIntersecting && !entry.target.classList.contains('animate')) {
      index++;
      (entry.target as HTMLElement).style.transitionDelay = `${index * 200}ms`;
      nextTick(() => {
        entry.target.classList.add('animate');
      });
    }
  });
});

setInterval(() => {
  const blocks = document.querySelectorAll('.animated-block:not(.observe)');
  blocks.forEach((block) => {
    block.classList.add('observe');
    observer.observe(block);
  });
}, 250);

watch([store.state, route], () => {
  if (store.state.user?.company_name && route.name && ['login', 'register'].includes(route.name.toString())) {
    router.push('/account/profile');
  } else if (store.state.user === null && route.name && route.name.toString().startsWith('account')) {
    router.push('/login');
  }
});

const closeNav = () => {
  navOpen.value = false;
};

onMounted(() => {
  window.addEventListener('click', closeNav);
  const hasTouch = ('ontouchstart' in document.documentElement);
  if (!hasTouch) {
    (window as any).butter.init();
  }
});
</script>

<style>
@font-face {
    font-family: 'Helvetica Neue';
    src: url('./assets/fonts/HelveticaNeue-Roman.woff2') format('woff2'),
        url('./assets/fonts/HelveticaNeue-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('./assets/fonts/HelveticaNeue-Medium.woff2') format('woff2'),
        url('./assets/fonts/HelveticaNeue-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('./assets/fonts/HelveticaNeue-Bold.woff2') format('woff2'),
        url('./assets/fonts/HelveticaNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

* {
  box-sizing: border-box;
  outline: none;
}

html, body {
  padding: 0;
  margin: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #303A5D;
  font: 400 16px/1.5 'Helvetica Neue';
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button {
  background: none;
  border-radius: 0;
  border: none;
  padding: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;
}

button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: bold;
}

h1 {
  font-size: 52px;
  line-height: 0.96;
  font-family: 'Rockwell';
}

h2 {
  font-size: 34px;
  line-height: 1.3;
  font-family: 'Rockwell';
}

h3 {
  font-size: 24px;
  line-height: 1.83;
  font-family: 'Rockwell';
}

h4 {
  text-transform: uppercase;
  font-size: 18px;
  color: #00ADEE;
}

h6 {
  font-size: 14px;
}

p {
  margin: 0;
  line-height: 1.625;
}

img:not([src]) {
  opacity: 0;
}

.page-container {
  min-height: 100vh;
}

.input {
  display: block;
  background: #FFF;
  border: none;
  font: inherit;
  color: #303A5D;
  width: 100%;
  height: 48px;
  border-radius: 0;
  padding: 0 24px;
  font-size: 14px;
}

textarea.input {
  height: 190px;
  padding: 8px 24px;
  resize: none;
}

select.input {
  background-image: url(./assets/ic-chevron-down.svg);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: calc(100% - 24px) center;
  appearance: none;
}

.input::placeholder {
  color: #303A5D;
  opacity: 0.69;
}

button.checkbox {
  width: 14px;
  height: 14px;
  background: #FFF;
  border: 1px solid #91C850;
}

button.checkbox[checked] {
  background: #91C850 url(./assets/ic-check.svg) no-repeat center/90% auto;
  border-color: #fff;
}

.wrapper {
  position: relative;
  width: 1440px;
  max-width: 100%;
  padding: 0 48px;
  margin: 0 auto;
}

.ic {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.ic.xs {
  width: 14px;
  height: 14px;
}

.ic.s {
  width: 16px;
  height: 16px;
}

.ic.m {
  width: 24px;
  height: 24px;
}

.ic.l {
  width: 32px;
  height: 32px;
}

.ic.xl {
  width: 64px;
  height: 64px;
}

.ic.ic-login {
  background-image: url(./assets/ic-login.svg);
}

.ic.ic-parrow-left {
  background-image: url(./assets/ic-parrow-left.svg);
}

.ic.ic-parrow-right {
  background-image: url(./assets/ic-parrow-right.svg);
}

.ic.ic-arrow-right {
  background-image: url(./assets/ic-arrow-right.svg);
}

.ic.ic-arrow-right-d {
  background-image: url(./assets/ic-arrow-right-d.svg);
}

.ic.ic-facebook {
  background-image: url(./assets/ic-facebook.svg);
}

.ic.ic-facebook-b {
  background-image: url(./assets/ic-facebook-b.svg);
}

.ic.ic-x {
  background-image: url(./assets/ic-x.svg);
}

.ic.ic-x-b {
  background-image: url(./assets/ic-x-b.svg);
}

.ic.ic-linkedin {
  background-image: url(./assets/ic-linkedin.svg);
}

.ic.ic-linkedin-b {
  background-image: url(./assets/ic-linkedin-b.svg);
}

.ic.ic-building {
  background-image: url(./assets/ic-building.svg);
}

.ic.ic-pin {
  background-image: url(./assets/ic-pin.svg);
}

.ic.ic-pin-w {
  background-image: url(./assets/ic-pin-w.svg);
}

.ic.ic-calendar {
  background-image: url(./assets/ic-calendar.svg);
}

.ic.ic-logo-b {
  background-image: url(./assets/ic-logo-b.svg);
}

.ic.ic-arrows-right {
  background-image: url(./assets/ic-arrows-right.svg);
}

.ic.ic-filter {
  background-image: url(./assets/ic-filter.svg);
}

.ic.ic-sort {
  background-image: url(./assets/ic-sort.svg);
}

.ic.ic-menu {
  background-image: url(./assets/ic-menu.svg);
}

.ic.ic-at {
  background-image: url(./assets/ic-at.svg);
}

.ic.ic-clock {
  background-image: url(./assets/ic-clock.svg);
}

.ic.ic-phone {
  background-image: url(./assets/ic-phone.svg);
}

.ic.ic-website {
  background-image: url(./assets/ic-website.svg);
}

.ic.ic-share {
  background-image: url(./assets/ic-share.svg);
}

.ic.ic-info {
  background-image: url(./assets/ic-info.svg);
}

.ic.ic-download {
  background-image: url(./assets/ic-download.svg);
}

.ic.ic-pdf {
  background-image: url(./assets/ic-pdf.svg);
}

.ic.ic-external {
  background-image: url(./assets/ic-external-g.svg);
}

.ic.ic-link {
  background-image: url(./assets/ic-link.svg);
}

.ic.ic-apply {
  background-image: url(./assets/ic-apply.svg);
}

.ic.ic-arrow-left {
  background-image: url(./assets/ic-arrow-left.svg);
}

.ic.ic-share-g {
  background-image: url(./assets/ic-share-g.svg);
}

.ic.ic-quote {
  background-image: url(./assets/ic-quote.svg);
}

.ic.ic-logo-full {
  background-image: url(./assets/ic-logo-full.svg);
}

.ic.ic-cog {
  background-image: url(./assets/ic-cog.svg);
}

.ic.ic-logout {
  background-image: url(./assets/ic-logout.svg);
}

.ic.ic-collaboration {
  background-image: url(./assets/ic-collaboration.svg);
}

.ic.ic-support {
  background-image: url(./assets/ic-support.svg);
}

.ic.ic-network {
  background-image: url(./assets/ic-network.svg);
}

.ic.ic-promotion {
  background-image: url(./assets/ic-promotion.svg);
}

.ic.ic-resources {
  background-image: url(./assets/ic-resources.svg);
}

.ic.ic-networking {
  background-image: url(./assets/ic-networking.svg);
}

.ic.ic-industry {
  background-image: url(./assets/ic-industry.svg);
}

.ic.ic-doc {
  background-image: url(./assets/ic-doc.svg);
}

.ic.ic-ppt {
  background-image: url(./assets/ic-ppt.svg);
}

.ic.ic-user {
  background-image: url(./assets/ic-user.svg);
}

.ic.ic-profile {
  background-image: url(./assets/ic-profile.svg);
}

.ic.ic-work {
  background-image: url(./assets/ic-work.svg);
}

.link {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font: bold 14px/1 'Rockwell';
  flex-shrink: 0;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 48px;
  min-width: 128px;
  padding: 0 32px;
  background: #91C850;
  color: #fff;
  font: bold 14px/1 'Rockwell';
  gap: 4px;
  flex-shrink: 0;
  white-space: nowrap;
}

.btn.blue {
  background: #00ADEE;
}

.btn.dark {
  background: #67A539;
}

.btn.outlined {
  background: none;
  border: 1px solid #91C850;
  color: #303A5D;
}

.btn.outlined.blue {
  border-color: #00ADEE;
}

.btn.outlined.black {
  border-color: #303A5D;
}

.btn.outlined.white {
  border-color: #fff;
  color: #fff;
}

.animated-block {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s ease-in;
  /* transition-delay: 0.2s; */
}

.animated-block.animate {
  opacity: 1;
  transform: none;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  z-index: 10;
}

header .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 96px;
}

header .header-actions {
  display: flex;
  align-items: center;
  gap: 24px;
}

header .header-logo {
  display: block;
}

header .account-button {
  position: relative;
}

header .account-button > .link {
  height: 48px;
}

header .account-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  color: #353638;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-out;
}

header .account-button:hover .account-menu {
  visibility: visible;
  opacity: 1;
}

header .account-menu .link {
  padding: 8px 12px;
  white-space: nowrap;
  width: 100%;
  gap: 8px;
}

header .account-menu .link:hover {
  background-color: rgba(48, 58, 93, 0.08);
}

header .mobile-nav,
header .mobile-nav-button {
  display: none;
}

nav {
  display: flex;
  align-items: center;
  height: 48px;
  gap: 16px;
  background: rgba(0, 173, 238, 0.69);
  padding: 0 40px;
}

nav .nav-item {
  color: #fff;
  font: bold 14px/1 'Rockwell';
}

nav .nav-item:not(:last-child):after {
  content: '/';
  display: inline-block;
  margin-left: 16px;
}

section {
  margin-bottom: 96px;
}

.title {
  text-align: center;
}

.subtitle {
  text-align: center;
  margin-top: 12px;
}

.subtitle.left {
  text-align: left;
}

.subtitle:after {
  content: '';
  display: block;
  width: 96px;
  height: 6px;
  background: #91C850;
  margin: 12px auto 0;
}

.subtitle.left:after {
  margin-left: 0;
}

.subtitle.white:after {
  background: #fff;
}

.subtitle.blue:after {
  background: #00ADEE;
}

.newsletter .wrapper {
  padding: 0;
}

.newsletter {
  display: flex;
}

.newsletter .column-left,
.newsletter .column-right {
  display: flex;
  align-items: center;
  min-height: 384px;
  color: #fff;
}

.newsletter .column-left {
  justify-content: flex-end;
  width: 43%;
  background: #00ADEE;
}

.newsletter .column-right {
  width: 57%;
  background: #91C850 url(./assets/meen-newsletter-form.svg) no-repeat 176px center/auto 100%;
}

.newsletter .column-left .content {
  max-width: 624px;
  padding: 48px 48px 48px 96px;
  flex-grow: 1;
}

.newsletter .column-left .content-text {
  padding: 16px 0 0 92px;
  background: url(./assets/meen-newsletter-logo.svg) no-repeat top left/134px auto;
}

.newsletter .column-left .content-text:after {
  content: '';
  display: block;
  width: 96px;
  height: 6px;
  background: #FFF;
  margin-top: 24px;
}

.newsletter .column-left .content-text p {
  margin-top: 12px;
}

.newsletter .column-right .content {
  max-width: 816px;
  padding: 48px 144px 48px 96px;
  flex-grow: 1;
}

.newsletter .input-button {
  display: flex;
  margin: 12px 0;
}

.newsletter .input-button .input {
  flex-grow: 1;
  padding-left: 48px;
  padding-top: 2px;
  background: #fff url(./assets/ic-email.svg) no-repeat 22px center/18px 18px;
}

.newsletter .field {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

.newsletter .field .checkbox {
  margin-top: -2px;
}

.newsletter .field a {
  font-weight: 700;
  text-decoration: underline;
}

.newsletter .newsletter-form {
  position: relative;
}

.newsletter .newsletter-form[disabled] > *:not(.success-message) {
  opacity: 0.5;
  pointer-events: none;
}

.newsletter .newsletter-form .success-message {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 8px;
  font-weight: 500;
}

.newsletter .newsletter-form .success-message.error {
  color: #f65252;
}

.partners .wrapper {
  width: 1344px;
}

.partners .container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.partners .logo-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 20%;
  padding: 0 32px;
}

.partners .logo-container:not(:last-child):after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1px;
  height: 34px;
  background: #A1A1A3;
  margin: auto;
}

.partners .logo-container .logo {
  display: block;
  max-width: 100%;
}

.join-network {
  position: relative;
  margin-bottom: 0;
}

.join-network:before,
.join-network:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: #00ADEE;
}

.join-network:after {
  left: 50%;
  background: #91C850;
}

.join-network .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 266px;
  padding-top: 48px;
  padding-bottom: 48px;
  z-index: 1;
  background: url(./assets/meen-join-network.svg) no-repeat center/auto 100%;
}

.join-network h2 {
  color: #fff;
  margin-bottom: 32px;
}

.join-network h2:after {
  content: '';
  display: block;
  width: 96px;
  height: 6px;
  background: #67A539;
  margin: 16px auto 0;
}

footer {
  background: #353638;
  color: #fff;
  padding: 48px 0 32px;
}

footer .wrapper {
  padding: 0 96px;
}

footer .container {
  display: flex;
  padding: 0 48px;
}

footer .column {
  width: 25%;
  padding-right: 24px;
}

footer .column-header {
  font: 700 22px/1 'Rockwell';
}

footer .column a {
  position: relative;
  display: block;
  color: #D9D9D9;
  font-size: 14px;
  line-height: 1;
  margin-top: 24px;
}

footer .column a:before {
  content: '/';
  position: absolute;
  top: 0;
  left: -16px;
}

footer .footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.23);
  padding-top: 24px;
  margin-top: 64px;
  font-size: 14px;
  color: #D9D9D9;
}

footer .footer-logo {
  display: block;
  width: 197px;
}

footer .footer-bottom a {
  text-decoration: underline;
}

@media screen and (max-width: 1340px) {
  header .wrapper {
    padding: 24px 32px;
  }
}

@media screen and (max-width: 1200px) {
  nav {
    padding: 0 24px;
    gap: 24px;
  }
  nav .nav-item:not(:last-child):after {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  header .mobile-nav,
  header .mobile-nav-button {
    display: flex;
  }
  header nav,
  header .header-actions {
    display: none;
  }
  header .mobile-nav {
    position: absolute;
    display: block;
    top: 64px;
    right: 32px;
    background: rgba(0, 173, 238, 0.69);
    padding: 8px 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-out;
  }
  header .mobile-nav[open] {
    opacity: 1;
    visibility: visible;
  }
  header .mobile-nav a {
    display: block;
    font: 700 14px/1 'Rockwell';
    padding: 10px 16px;
  }
  section {
    margin-bottom: 64px;
  }
  .newsletter .column-left .content {
    padding: 48px;
  }
  .newsletter .column-left .content-text {
    padding-left: 24px;
    padding-top: 24px;
  }
  .newsletter .column-right .content {
    padding: 48px;
  }
  .partners .container {
    gap: 48px 0;
    justify-content: center;
  }
  .partners .logo-container {
    width: 33.333333%;
  }
  .partners .logo-container:nth-child(3):after {
    display: none;
  }
  footer .wrapper {
    padding: 0 48px;
  }
  footer .footer-bottom {
    flex-wrap: wrap;
  }
  footer .footer-copyright {
    order: 3;
    width: 100%;
    text-align: center;
    margin-top: 24px;
  }
}

@media screen and (max-width: 800px) {
  .newsletter {
    display: block;
  }
  .newsletter .column-left, .newsletter .column-right {
    width: auto;
  }
  .newsletter .column-left .content {
    max-width: none;
    padding-left: 24px;
  }
  .newsletter .column-left {
    min-height: 0;
    padding-bottom: 24px;
  }
  .newsletter .column-right {
    min-height: 0;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .partners .logo-container {
    width: 50%;
  }
  .partners .logo-container .logo {
    max-height: 100px;
  }
  .partners .logo-container:not(:last-child):after {
    display: none;
  }
  footer .container {
    flex-wrap: wrap;
    gap: 32px 0;
    padding: 0;
  }
  footer .column {
    width: 50%;
  }
}

@media screen and (max-width: 640px) {
  footer .footer-bottom {
    flex-direction: column;
  }
  footer .footer-sign {
    margin-top: 32px;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 44px;
  }
  h2 {
    font-size: 28px;
  }
  .newsletter .column-left .content {
    padding-left: 0;
    padding-right: 24px;
  }
  .newsletter .column-right .content {
    padding-left: 24px;
    padding-right: 24px;
  }
  .partners .wrapper {
    padding: 0;
  }
  .partners .logo-container {
    padding: 0 24px;
  }
  footer .container {
    padding: 0 24px;
  }
  footer .wrapper {
    padding: 0 24px;
  }
}
</style>
