<template>
  <section class="resources">
    <div class="wrapper">
      <div class="container">
        <div class="column-left animated-block">
          <h2>Resources</h2>
          <div class="subtitle">Mastering Engineering Dynamics</div>
          <p>Explore useful links for training opportunities and funding resources to support your business's growth and development journey carefully curated for professionals seeking excellence, including grant information, useful links and industry insights.</p>
          <router-link to="/resources" class="btn">
            Navigate resources
            <i class="ic ic-arrow-right"></i>
          </router-link>
        </div>
        <div class="column-right animated-block">
          <img src="@/assets/meen-home-resources.jpg" alt="">
          <div class="tag">Everything you need is here!</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>

</script>

<style scoped>
.resources {
  padding-top: 64px;
}

.resources .wrapper {
  padding: 0;
}

.resources .subtitle {
  text-align: left;
}

.resources .subtitle:after {
  margin-left: 0;
}

.resources .container {
  display: flex;
}

.resources .column-left {
  position: relative;
  width: 576px;
  max-width: 50%;
  padding: 8px 32px 16px 96px;
}

.resources .column-left:before {
  content: '';
  position: absolute;
  top: -64px;
  left: 16px;
  width: 160px;
  height: 160px;
  background: url(../assets/meen-home-resources.svg) no-repeat center/contain;
}

.resources .column-left p {
  margin: 24px 0 32px;
}

.resources .column-right {
  position: relative;
  flex-grow: 1;
}

.resources .column-right:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00ADEE;
  opacity: 0.23;
  mix-blend-mode: color;
}

.resources .column-right img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.resources .column-right .tag {
  position: absolute;
  bottom: 24px;
  right: 96px;
  background: #91C850;
  color: #FFF;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 14px 2px 14px;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .resources .column-left {
    padding-left: 48px;
    width: 50%;
    flex-shrink: 0;
  }
  .resources .column-right .tag {
    right: 48px;
  }
}

@media screen and (max-width: 640px) {
  .resources .container {
    display: block;
  }
  .resources .column-left {
    width: auto;
    max-width: none;
    margin-bottom: 48px;
  }
  .resources {
    margin-bottom: 0;
  }
}
</style>
