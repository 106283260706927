import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyD-Jf8jPXjtx1Br0NpGeS-yJMpyyNlM9w0",
  authDomain: "midlands-engineering-network.firebaseapp.com",
  projectId: "midlands-engineering-network",
  storageBucket: "midlands-engineering-network.appspot.com",
  messagingSenderId: "506792378309",
  appId: "1:506792378309:web:7a6e62cc358fcdde22d5ac",
  measurementId: "G-L5SMH9JPLJ",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const firestore = getFirestore(app);

export const storage = getStorage(app);

export const functions = getFunctions(app, 'europe-west1');

export const uploadFile = async (path: string, file: File) => {
  const fullPath = `${path}/${file.name}`;
  const name = file.name.split('.');
  const ext = name.pop();
  const fileRef = ref(storage, fullPath);
  const snap = await uploadBytes(fileRef, file);
  const url = await getDownloadURL(snap.ref);
  return {
    key: name.join('.'),
    ext,
    file: file.name,
    path: fullPath,
    url,
  };
};
