<template>
  <div class="page home">
    <section class="hero-banner">
      <div class="wrapper">
        <div class="hero-banner-content animated-block">
          <h1>Build connections<br>and share engineering ideas</h1>
          <router-link class="btn" to="/register">
            Join the network
            <i class="ic ic-arrow-right"></i>
          </router-link>
        </div>
        <div class="hero-banner-bottom animated-block">
          <b>Follow us</b>
          <a href="https://www.facebook.com/profile.php?id=100063737761079" target="_blank" rel="noopener noreferrer" class="ic s ic-facebook"></a>
          /
          <a href="https://www.linkedin.com/in/engenuity-midlands-engineering-network-1a30881aa/" target="_blank" rel="noopener noreferrer" class="ic s ic-linkedin"></a>
          /
          <a href="https://x.com/Engenuity2" target="_blank" rel="noopener noreferrer" class="ic s ic-x"></a>
        </div>
      </div>
    </section>
    <section class="cta-text">
      <div class="wrapper">
        <div class="content animated-block">
          <div class="cta-text-content">The Engenuity Engineering Network was set up to promote market-led innovation, stakeholder collaboration, best practices and product / trade development among the Midlands Engineering Cluster.</div>
          <router-link class="link" to="/members">
            Read more
            <i class="ic ic-arrow-right-d"></i>
          </router-link>
        </div>
      </div>
    </section>
    <section class="highlight">
      <div class="wrapper">
        <div class="container">
          <div class="column-left animated-block">
            <div class="column-subtitle">Mission</div>
            <h2>We help members build links, share experience and undertake joint commercial activity.</h2>
            <img src="@/assets/meen-home-mission.jpg" alt="" class="column-image">
          </div>
          <div class="column-right animated-block">
            <div class="column-text">The network will include events to share expertise and best practice based around specific topics, facilitate guest speakers, member presentations, workshops and networking (physical and virtual) so that companies can learn from each other and access support from peers and stakeholder organisations, such as the industry, knowledge sector and other enterprise support bodies</div>
            <router-link class="btn" to="/register">
              Access support
              <i class="ic ic-arrow-right"></i>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="columns">
      <div class="wrapper">
        <div class="container">
          <div class="column-left animated-block">
            <div class="column-subtitle">Network</div>
            <h2>Becoming a member</h2>
            <div class="column-text">Becoming a member will allow you to showcase your company to an extended online audience and raise awareness of products/services, access a private resource section also with useful information, industry data and other sector-specific knowledge. You’ll also have access to network with industry and peers to exchange of ideas and opportunities.</div>
            <router-link class="btn blue" to="/about">
              More about us
              <i class="ic ic-arrow-right"></i>
            </router-link>
          </div>
          <div class="column-right animated-block">
            <i class="ic xl ic-building"></i>
            <div class="column-count">{{ companyCount }}</div>
            <div class="column-unit">Companies</div>
            <div class="column-text">are part of the network</div>
            <router-link class="btn dark" to="/register">
              Join the network
              <i class="ic ic-arrow-right"></i>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="jobs" v-if="jobs.length">
      <div class="wrapper">
        <h2 class="title animated-block">Job opportunities</h2>
        <div class="subtitle animated-block">Discover Companies Seeking Talent in Our Network</div>
        <div class="container">
          <img src="@/assets/meen-home-jobs.jpg" alt="" class="jobs-cover animated-block">
          <div class="jobs-list">
            <div class="item animated-block" v-for="job in jobs" :key="job.id">
              <JobPost :post="job" />
            </div>
          </div>
        </div>
        <div class="actions animated-block">
          <router-link class="btn outlined" to="/jobs">view all jobs</router-link>
        </div>
      </div>
    </section>
    <section class="sectors">
      <div class="wrapper">
        <div class="container">
          <div class="section-banner animated-block">
            <h2 class="title">Engenuity Sectors</h2>
            <div class="subtitle white">The Network currently consists of member companies with expertise in a variety of sectors.</div>
          </div>
          <div class="items">
            <router-link class="sector animated-block" to="/members?sector=Metal Fabrication">
              <img src="@/assets/meen-sector-metal.jpg" alt="" class="sector-image">
              <div class="sector-name">Metal Fabrication</div>
            </router-link>
            <router-link class="sector animated-block" to="/members?sector=Design">
              <img src="@/assets/meen-sector-1.jpg" alt="" class="sector-image">
              <div class="sector-name">Design</div>
            </router-link>
            <router-link class="sector animated-block" to="/members?sector=Manufacturing Services">
              <img src="@/assets/meen-sector-2.jpg" alt="" class="sector-image">
              <div class="sector-name">Manufacturing Services</div>
            </router-link>
            <router-link class="sector animated-block" to="/members?sector=Installation">
              <img src="@/assets/meen-sector-3.jpg" alt="" class="sector-image">
              <div class="sector-name">Installation</div>
            </router-link>
            <router-link class="sector animated-block" to="/members?sector=Distribution">
              <img src="@/assets/meen-sector-4.jpg" alt="" class="sector-image">
              <div class="sector-name">Distribution</div>
            </router-link>
            <router-link class="sector animated-block" to="/members?sector=Robotics">
              <img src="@/assets/meen-sector-5.jpg" alt="" class="sector-image">
              <div class="sector-name">Robotics</div>
            </router-link>
          </div>
          <div class="actions animated-block">
            <router-link class="btn outlined" to="/about#sectors">view all sectors</router-link>
            <router-link class="btn" to="/register">Join the network</router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="events" v-if="events.length">
      <div class="wrapper">
        <h2 class="title animated-block">Events</h2>
        <div class="subtitle blue animated-block">A Comprehensive Guide to Upcoming Occasions and Gatherings</div>
        <div class="main-event animated-block">
          <router-link :to="`/events/${events[0].slug}`">
            <img v-if="events[0].picture" :src="events[0].picture.url" alt="" class="main-event-image">
          </router-link>
          <div class="main-event-date">
            <div class="day">{{ format(events[0].start_date, 'dd') }}</div>
            <div class="month">{{ format(events[0].start_date, 'MMM') }}.</div>
          </div>
          <router-link :to="`/events/${events[0].slug}`" class="main-event-info">
            <div class="main-event-header">
              <button class="ic m ic-parrow-left"></button>
              <div class="main-event-title">{{ events[0].title }}</div>
              <button class="ic m ic-parrow-right"></button>
            </div>
            <div class="main-event-location">
              <span>
                <i class="ic s ic-pin-w"></i>
                {{ events[0].location }}
              </span>
              /
              <span>{{ format(events[0].start_date, 'HH:mm') }}{{ events[0].end_date ? ` - ${format(events[0].end_date, 'HH:mm')}` : '' }} GMT</span>
            </div>
            <div class="main-event-excerpt">
              {{ events[0].description }}
            </div>
          </router-link>
        </div>
        <div class="events-list" v-if="events.length > 1">
          <h4 class="animated-block">Upcoming events</h4>
          <router-link class="event-post animated-block" :to="`/events/${event.slug}`" v-for="event in events.slice(1)" :key="event.id">
            <div class="event-post-date">
              <div class="day">{{ format(event.start_date, 'dd') }}</div>
              <div class="month">{{ format(event.start_date, 'MMM') }}.</div>
            </div>
            <div class="event-post-info">
              <div class="event-post-title">{{ event.title }}</div>
              <div class="event-post-location">
                <span>
                  <div class="ic s ic-pin"></div>
                  {{ event.location }}
                </span>
                /
                <span>{{ event.city }}</span>
                /
                <span>{{ format(event.start_date, 'HH:mm') }}{{ event.end_date ? ` - ${format(event.end_date, 'HH:mm')}` : '' }} GMT</span>
              </div>
            </div>
          </router-link>
        </div>
        <div class="actions animated-block">
          <router-link class="btn outlined blue" to="/events">browse all events</router-link>
        </div>
      </div>
    </section>
    <section class="news" v-if="posts.length">
      <div class="wrapper">
        <h2 class="title animated-block">News</h2>
        <div class="subtitle blue animated-block">Unveiling Stories: Inside the Latest News Highlights</div>
        <NewsPost class="animated-block" layout="main" :post="posts[0]" />
        <div class="latest-news">
          <h4 class="blue animated-block">Latest news</h4>
          <div class="container">
            <div class="item animated-block" v-for="post in posts.slice(1)" :key="post.id">
              <NewsPost :post="post" />
            </div>
          </div>
        </div>
        <div class="actions animated-block">
          <router-link class="btn outlined blue" to="/news">see all news</router-link>
        </div>
      </div>
    </section>
    <section class="featured-members" v-if="members.length">
      <div class="wrapper">
        <h2 class="title">Featured members</h2>
        <div class="subtitle">Celebrating Our Network: Highlighting Key Contributors and Influencers</div>
        <div class="container">
          <router-link v-for="member in members" :key="member.id" class="member animated-block" :to="`/members/${member.slug}`">
            <img :src="member.picture?.url" alt="" class="member-image">
            <div class="member-name">{{ member.company_name }}</div>
          </router-link>
          <router-link class="member all animated-block" to="/members">
            <i class="ic xl ic-logo-b"></i>
            <div><b>Midlands Engineering Network</b></div>
            <div><i>discover all members</i></div>
            <i class="ic m ic-arrows-right"></i>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import NewsPost from '../components/NewsPost.vue';
import { BlogPost } from '../types/BlogPost';
import { EventPost } from '../types/EventPost';
import { Job } from '../types/Job';
import { Company } from '../types/Company';
import { Unsubscribe, collection, getCountFromServer, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { format } from 'date-fns';
import { firestore } from '@/services/FirebaseService';
import JobPost from '@/components/JobPost.vue';

const posts = ref<BlogPost[]>([]);
const events = ref<EventPost[]>([]);
const members = ref<Company[]>([]);
const jobs = ref<Job[]>([]);
const postsRef = ref<Unsubscribe | null>(null);
const eventsRef = ref<Unsubscribe | null>(null);
const membersRef = ref<Unsubscribe | null>(null);
const jobsRef = ref<Unsubscribe | null>(null);
const companyCount = ref<number>(0);

const getCompanyCount = async () => {
  const { count } = (await getCountFromServer(query(collection(firestore, 'companies'), where('accepted', '==', true)))).data();
  companyCount.value = count;
};

onMounted(() => {
  postsRef.value = onSnapshot(query(collection(firestore, 'posts'), where('type', '==', 'news'), where('active', '==', true), orderBy('created_at', 'desc'), limit(4)), (snap) => {
    posts.value = snap.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        categories: data.categories || [],
        published_at: data.published_at?.toDate(),
        created_at: data.created_at.toDate(),
        updated_at: data.created_at.toDate(),
      } as BlogPost;
    });
    console.log(posts.value);
  });
  eventsRef.value = onSnapshot(query(collection(firestore, 'posts'), where('type', '==', 'event'), where('active', '==', true), where('start_date', '>=', new Date()), orderBy('start_date', 'asc'), limit(4)), (snap) => {
    events.value = snap.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        start_date: data.start_date.toDate(),
        end_date: data.end_date?.toDate(),
        created_at: data.created_at.toDate(),
        updated_at: data.updated_at.toDate(),
      } as EventPost;
    });
    console.log(events.value);
  });
  membersRef.value = onSnapshot(query(collection(firestore, 'companies'), where('accepted', '==', true), where('featured', '==', true), orderBy('created_at', 'desc'), limit(3)), (snap) => {
    members.value = snap.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        created_at: data.created_at.toDate(),
        updated_at: data.updated_at.toDate(),
      } as unknown as Company;
    });
    console.log(members.value);
  });
  jobsRef.value = onSnapshot(query(collection(firestore, 'jobs'), where('active', '==', true), orderBy('created_at', 'desc'), limit(3)), (snap) => {
    jobs.value = snap.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        created_at: data.created_at.toDate(),
        updated_at: data.updated_at.toDate(),
      } as unknown as Job;
    });
    console.log(jobs.value);
  });
  getCompanyCount();
});

onBeforeUnmount(() => {
  postsRef.value?.();
  eventsRef.value?.();
  membersRef.value?.();
  jobsRef.value?.();
});
</script>

<style scoped>
.hero-banner {
  position: relative;
  display: flex;
  align-items: stretch;
  min-height: 100vh;
  background: url(../assets/meen-home-banner.jpg) no-repeat center/cover;
  padding-top: 112px;
}

.hero-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 173, 238, 0.33);
}

.hero-banner .hero-banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding-bottom: 64px;
  gap: 48px;
  text-align: center;
  color: #fff;
}

.hero-banner .hero-banner-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-family: 'Rockwell';
}

.hero-banner .hero-banner-bottom b {
  margin-right: 4px;
}

.cta-text .content {
  display: flex;
  align-items: center;
  gap: 80px;
  padding: 0 48px;
}

.cta-text .cta-text-content {
  flex-grow: 1;
  font-size: 20px;
}

.highlight {
  margin-bottom: 208px;
}

.highlight .container {
  display: flex;
  background: #F5F6FB;
  border-top: 4px solid #91C850;
}

.highlight .container .column-left,
.highlight .container .column-right {
  width: 50%;
}

.highlight .container h2 {
  margin: 64px 0;
  max-width: 572px;
}

.highlight .container .column-left {
  width: 53.6%;
  padding-top: 16px;
  padding-left: 48px;
}

.highlight .container .column-right {
  width: 46.4%;
  padding-top: 92px;
  padding-bottom: 32px;
  padding-left: 96px;
  padding-right: 144px;
  line-height: 1.625;
}

.highlight .container .column-image {
  display: block;
  width: 100%;
  margin-bottom: -112px;
}

.highlight .container .column-subtitle {
  line-height: 1;
  color: #67A539;
  font-weight: 700;
  font-size: 18px;
}

.highlight .container .column-right .btn {
  width: 100%;
  margin-top: 64px;
}

.columns .container {
  position: relative;
  display: flex;
}

.columns .container:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 160px;
  height: 125px;
  margin: auto;
  background: url(../assets/ic-cogs.svg) no-repeat center/contain;
}

.columns .column-left,
.columns .column-right {
  width: 50%;
  padding: 64px 144px;
}

.columns .column-left {
  background: #F5F6FB;
}

.columns .column-right {
  background: #91C850;
  color: #fff;
  text-align: center;
}

.columns .btn {
  width: 100%;
  margin-top: 64px;
}

.columns .column-subtitle {
  color: #00ADEE;
  font-size: 18px;
  font-weight: 700;
}

.columns .column-count {
  font: 700 80px/1 'Rockwell';
}

.columns .column-unit {
  font: 400 46px/1 'Rockwell';
}

.columns .column-text {
  line-height: 1.625;
  margin-top: 24px;
}

.columns .column-right .column-text:after {
  content: '';
  display: block;
  width: 96px;
  height: 6px;
  margin: 16px auto 0;
  background: #67A539;
}

.jobs {
  padding-top: 48px;
}

.jobs .container {
  display: flex;
  margin-top: 48px;
  padding: 0 48px;
}

.jobs .jobs-cover {
  object-fit: cover;
}

.jobs .jobs-cover,
.jobs .jobs-list {
  width: 50%;
}

.jobs .jobs-list {
  padding-left: 48px;
}

.jobs .item {
  margin-bottom: 24px;
}

.jobs .actions {
  text-align: center;
  margin-top: 56px;
}

.jobs .actions .btn {
  min-width: 224px;
}

.section-banner {
  border-top: 4px solid #91C850;
  background: url(../assets/meen-banner-sectors.png) no-repeat center/cover;
  color: #fff;
  padding: 114px 32px 64px;
}

.sectors {
  position: relative;
  padding-bottom: 64px;
}

.sectors:before {
  content: '';
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #F5F6FB;
}

.sectors .items {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 56px;
}

.sectors .sector {
  width: calc(16.666666666% - 13.333333px);
  background: #fff;
  border-bottom: 2px solid #91C850;
}

.sectors .sector .sector-image {
  display: block;
  width: 100%;
  height: 224px;
  object-fit: cover;
}

.sectors .sector .sector-name {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  font: 700 16px/normal 'Rockwell';
  text-align: center;
  min-height: 80px;
}

.sectors .actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-top: 128px;
}

.actions .btn {
  min-width: 224px;
}

.events .wrapper {
  padding: 0;
}

.main-event {
  position: relative;
  height: 768px;
  margin-top: 48px;
  margin-bottom: 80px;
}

.main-event .main-event-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-event .main-event-info {
  position: absolute;
  bottom: -32px;
  left: 96px;
  right: 96px;
}

.main-event .main-event-header {
  display: flex;
  align-items: center;
  background: rgba(0, 173, 238, 0.77);
  color: #fff;
  padding: 32px 40px;
  gap: 36px;
}

.main-event .main-event-title {
  font: 700 28px/1 'Rockwell';
  flex-grow: 1;
}

.main-event .main-event-location {
  display: flex;
  align-items: center;
  background: #00ADEE;
  color: #fff;
  padding: 8px 96px;
  gap: 12px;
  font-size: 14px;
}

.main-event .main-event-location span {
  display: flex;
  align-items: center;
  gap: 4px;
}

.main-event .main-event-location span .ic {
  margin-top: -4px;
}

.main-event .main-event-excerpt {
  background: #F5F6FB url(../assets/ic-chevron-right.svg) no-repeat 96.5% center/16px 16px;
  padding: 32px 96px;
}

.main-event-date {
  position: absolute;
  text-align: center;
  color: #fff;
  top: 0;
  padding-top: 30px;
  left: 96px;
  width: 96px;
  height: 128px;
  background: url(../assets/ic-event-date.svg) no-repeat center/contain;
}

.main-event-date .day {
  font: 700 32px/1 'Rockwell';
}

.main-event-date .month {
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 6px;
}

.events-list {
  margin-top: 80px;
  padding: 0 96px;
}

.events-list h4 {
  margin-bottom: 24px;
  line-height: 1;
}

.event-post {
  display: flex;
  align-items: center;
  background: #F5F6FB url(../assets/ic-chevron-right.svg) no-repeat 97% center/16px 16px;
  margin-bottom: 16px;
}

.event-post .event-post-date {
  background: #00ADEE;
  padding: 12px 16px;
  text-align: center;
  color: #FFF;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.event-post .event-post-date .day {
  font: 700 24px/1 'Rockwell';
  margin-bottom: 4px;
}

.event-post .event-post-info {
  padding: 12px 32px;
}

.event-post .event-post-title {
  font: 700 18px/1 'Rockwell';
  margin-bottom: 8px;
}

.event-post .event-post-location {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  opacity: 0.69;
}

.event-post .event-post-location span {
  display: flex;
  align-items: center;
  gap: 4px;
}

.event-post .event-post-location span .ic {
  margin-top: -4px;
}

.events .actions {
  text-align: center;
  margin-top: 48px;
}

.news .subtitle {
  margin-bottom: 48px;
}

.news .container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.news .container .item {
  width: calc(33.333333% - 16px);
}

.news .container .item >>> .news-post {
  height: 100%;
}

.news .latest-news {
  margin-top: 64px;
  padding: 0 96px;
}

.news .latest-news h4 {
  margin-bottom: 16px;
}

.news .actions {
  text-align: center;
  margin-top: 40px;
}

.featured-members {
  background: #F5F6FB;
  padding: 32px 0 112px;
  margin-bottom: 64px;
}

.featured-members .container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 48px;
  gap: 32px;
  margin-top: 48px;
}

.featured-members .member {
  width: calc(25% - 24px);
  background: #fff;
}

.featured-members .member.all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.featured-members .member.all .ic.xl {
  margin-bottom: 16px;
}

.featured-members .member .member-image {
  display: block;
  width: 100%;
  height: 352px;
  object-fit: cover;
}

.featured-members .member .member-name {
  padding: 20px;
  text-align: center;
  font: 700 16px/1 'Rockwell';
}

@media screen and (max-width: 1200px) {
  .highlight .container .column-right {
    padding-left: 48px;
    padding-right: 48px;
  }
  .columns .column-left {
    padding-left: 48px;
    padding-right: 96px;
  }
  .columns .column-right {
    padding: 64px 96px;
  }
}

@media screen and (max-width: 1024px) {
  .columns .wrapper {
    padding: 0;
  }
  .jobs .jobs-cover {
    display: none;
  }
  .jobs .jobs-list {
    width: 100%;
    padding: 0;
  }
  .jobs .actions {
    margin-top: 32px;
  }
  .sectors .sector {
    width: calc(25% - 12px);
  }
  .main-event {
    height: 580px;
  }
  .main-event .main-event-info {
    left: 0;
    right: 0;
  }
  .main-event .main-event-location,
  .main-event .main-event-excerpt {
    padding-left: 40px;
    padding-right: 40px;
    font-size: 14px;
  }
  .main-event-date {
    left: 40px;
  }
  .main-event .main-event-header button {
    display: none;
  }
  .events .title,
  .events .subtitle {
    padding: 0 40px;
  }
  .events-list {
    padding: 0 40px;
  }
  .event-post .event-post-location {
    flex-wrap: wrap;
    gap: 4px 12px;
  }
  .event-post .event-post-info {
    padding-left: 24px;
  }
  .event-post .event-post-date {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .news .latest-news {
    padding: 0;
  }
  .featured-members .member {
    width: calc(50% - 16px);
  }
  .jobs {
    padding-top: 0;
  }
}

@media screen and (max-width: 800px) {
  .cta-text .content {
    padding: 0;
  }
  .highlight {
    margin-bottom: 96px;
  }
  .highlight .container {
    flex-direction: column;
  }
  .highlight .container .column-left,
  .highlight .container .column-right {
    width: 100%;
  }
  .highlight .container .column-image {
    margin-bottom: 48px;
  }
  .highlight .container .column-right {
    padding-top: 0;
  }
  .highlight .container .column-left {
    padding-right: 48px;
  }
  .columns .container {
    flex-direction: column;
  }
  .columns .container .column-left,
  .columns .container .column-right {
    width: 100%;
    padding: 64px 48px;
  }
  .columns .container:after {
    display: none;
  }
  .highlight .wrapper {
    padding: 0;
  }
  .sectors .sector {
    width: calc(33.333333% - 10.666666px);
  }
  .sectors .actions {
    margin-top: 64px;
    flex-wrap: wrap;
  }
  .news .container .item {
    width: 100%;
  }
  .featured-members {
    padding-bottom: 64px;
  }
  .featured-members .container {
    padding: 0;
  }
}

@media screen and (max-width: 640px) {
  .cta-text .content {
    flex-direction: column;
  }
  .event-post .event-post-title {
    font-size: 16px;
  }
  .events-list {
    padding: 0 24px;
  }
  .news .wrapper {
    padding: 0;
  }
  .news .latest-news {
    padding: 0 24px;
  }
  .featured-members .member {
    width: 100%;
  }
  .featured-members .member.all {
    padding: 32px 0;
  }
  .section-banner {
    margin: 0 -48px;
  }
  .jobs .container {
    padding: 0;
  }
  .cta-text .content {
    gap: 48px;
  }
  .cta-text .cta-text-content {
    font-size: 18px;
  }
}

@media screen and (max-width: 580px) {
  .sectors .sector {
    width: calc(50% - 8px);
  }
}

@media screen and (max-width: 480px) {
  .jobs .wrapper {
    padding: 0 24px;
  }
}

@media screen and (max-width: 420px) {
  .sectors .sector {
    width: 100%;
  }
}
</style>