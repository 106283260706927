import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/NewsView.vue'),
  },
  {
    path: '/news/:id',
    name: 'blog-post',
    component: () => import(/* webpackChunkName: "blog-post" */ '../views/PostView.vue'),
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/JobsView.vue'),
  },
  {
    path: '/jobs/:id',
    name: 'job',
    component: () => import(/* webpackChunkName: "job" */ '../views/JobView.vue'),
  },
  {
    path: '/events',
    name: 'events',
    component: () => import(/* webpackChunkName: "events" */ '../views/EventsView.vue'),
  },
  {
    path: '/events/:id',
    name: 'event',
    component: () => import(/* webpackChunkName: "event" */ '../views/EventView.vue'),
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import(/* webpackChunkName: "resources" */ '../views/ResourcesView.vue'),
  },
  {
    path: '/members',
    name: 'members',
    component: () => import(/* webpackChunkName: "members" */ '../views/MembersView.vue'),
  },
  {
    path: '/members/:id',
    name: 'member',
    component: () => import(/* webpackChunkName: "member" */ '../views/MemberView.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
  },
  {
    path: '/account/profile',
    name: 'account-profile',
    component: () => import(/* webpackChunkName: "account-profile" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/jobs',
    name: 'account-jobs',
    component: () => import(/* webpackChunkName: "account-jobs" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/jobs/:id',
    name: 'account-job',
    component: () => import(/* webpackChunkName: "account-job" */ '../views/AccountView.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/RegisterView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
  },
  {
    path: '/:id',
    name: 'custom-page',
    component: () => import(/* webpackChunkName: "custom-page" */ '../views/PageView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    const hasTouch = ('ontouchstart' in document.documentElement);
    if (!hasTouch) {
      (window as any).butter.cancel();
    }
    if (!window.location.hash) {
      window.scrollTo({ top: 0 });
    }
    if (from?.name !== to?.name) {
      console.log(from, to);
      setTimeout(() => {
        const blocks = document.querySelectorAll('footer .animated-block.animate, .partners .animated-block.animate, .join-network.animated-block.animate, .newsletter .animated-block.animate, .resources .animated-block.animate');
        blocks.forEach((block) => {
          block.classList.remove('animate');
        });
      }, 250);
    }
    if (!hasTouch) {
      (window as any).butter.init();
    }
  },
});

export default router;
